import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/hooks';
import { setAstraAuthCode } from '../../astraSlice';
import { AstraCard } from '../../components';

import './AstraRoute.scss';
import { POSTAstraAuthCode } from '../../../../datasource';

export function AstraRoute() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const astraOauthUrl = sessionStorage.getItem('astra_oauth') ?? "http://localhost:3006?redirect_url=http://localhost:3000/astra/success";
  
  const onSuccess = (auth_code:POSTAstraAuthCode) => {
    dispatch(setAstraAuthCode(auth_code))
    navigate('../confirm', { relative: "path" })
  }

  useEffect(() => {
    const cleanup = () => {
      window.removeEventListener('message', handleEvent);
    }

    const handleEvent = (event:MessageEvent) => {
      const { data, origin } = event;
      if (origin === process.env.REACT_APP_SERVER_URL && data.type === 'success' && !!data.payload) {
        cleanup();
        onSuccess(data.payload.auth_code);
      }
    };

    window.addEventListener('message', handleEvent, false);
  });

  return (
    <div className='astra-route-component'>
      <AstraCard astraOauthUrl={astraOauthUrl} />
    </div>
  );
}
