import { ReactComponent as Logo } from '../../../../assets/images/logo.svg';
import { ReactComponent as Calling } from '../../../../assets/images/calling.svg';
import { ReactComponent as Message } from '../../../../assets/images/message.svg';
import { ReactComponent as Facebook } from '../../../../assets/images/facebook.svg';
import { ReactComponent as Instagram } from '../../../../assets/images/instagram.svg';
import { ReactComponent as LinkedIn } from '../../../../assets/images/linkedin.svg';
import { ReactComponent as Xtwitter } from '../../../../assets/images/x.svg';
import { useAppSelector } from '../../../../app/hooks';
import { toDollarString } from '../../../../lib/stringRenderHelper';
import CardsImage from '../../../../assets/images/splash.png';
import QRCodeImage from '../../../../assets/images/qrcode.png';

import './SuccessCard.scss';

export function SuccessCard() {
  const startState = useAppSelector(state => state.start);
  const informationState = useAppSelector(state => state.information);

  const dollarAmount = toDollarString(startState.form.amount);
  const senderEmail = informationState.form.email;
  const recipient = startState.givenName;

  return (
    <div className='success-card-component column'>
      <div className='blue-bg'>
        <div className='title-container'>
          <h1 className='title'>Payment sent</h1>
          <h2 className='subtitle'>You just sent {dollarAmount} to {recipient}</h2>
          <div className='separator primary200' />
          <h3 className='notice'>You’ll receive a receipt at {senderEmail}</h3>
        </div>

        <div className='splash-container flex-center'>
          <img src={CardsImage} className='cards-image' alt='The Till Financial debit card in front of two phones showing the Till mobile application; one showing the parents view, the other showing the childs.'/>
        </div>
      </div>

      <div className='learn-more-container flex-center column'>
        <div className='title-container'>
          <h2 className='title'>Start using Till with your family</h2>
          <p className='subtitle'>Till is a free banking app and debit card for kids and teens that’s designed to teach financial literacy through real-world experience. Till’s platform offers savings goals, automated allowance, financial literacy, and much more.</p>
        </div>
        <div className='cta-container'>
          <a className='cta-button mb' target='_blank' rel='noreferrer' href='https://tillfinancial.io'>&nbsp;Learn more&nbsp;</a>
        </div>
      </div>

      <div className='get-started-container flex-center'>
          <div className='callout-container flex-center'>
            <div className='title-container flex-center'>
              <div className='title-wrapper'>
                <h2 className='title'>Download Till to get started now</h2>
                <h3 className='subtitle'>Available for free on iOS and Android</h3>
                <div className='cta-container'>
                  <a className='cta-button mb' target='_blank' rel='noreferrer' href='https://tillfinancial.app.link/paylink'>Get Till now</a>
                </div>
              </div>
            </div>
            <div className='qr-cta-container flex-center column'>
              <div className='cta-qr-code-wrapper flex-center column'>
                <img className='cta-qr-code-image' src={QRCodeImage} alt='Scan the qr code to download the Till mobile application'/>
                <a href='https://tillfinancial.app.link/paylink' className='black-cta-button' target='_blank' rel='noreferrer'>Scan to download</a>
              </div>
            </div>
        </div>
      </div>
      <footer>
        <div className='row mb'>
            <div className='column flex'>
              <h2 className='mb'>Have any questions?</h2>
              <p className='row'><Message className='svg-icon'/><a className='footer-nav' href="mailto:help@tillfinancial.io">help@tillfinancial.io</a></p>
              <p className='row'><Calling className='svg-icon'/><a className='footer-nav' href="tel:+14243778615">+1 (424) 377-8615</a></p>
            </div>
            <div className='column flex'>
              <h3 className='mb'>About Till</h3>
              <a target='_blank' rel='noreferrer' href='https://tillfinancial.io/' className='footer-nav'>Home</a>
              <a target='_blank' rel='noreferrer' href='https://tillfinancial.io/faq' className='footer-nav'>FAQs</a>
              <a target='_blank' rel='noreferrer' href='mailto:help@tillfinancial.io' className='footer-nav'>Contact us</a>
              <a target='_blank' rel='noreferrer' href='https://tillfinancial.app.link/paylink' className='footer-nav'>Download Till</a>
              <a target='_blank' rel='noreferrer' href='https://tillfinancial.io/policies/' className='footer-nav'>Policies</a>
            </div>

            <div className='column flex-grow'>
              <h3 className='mb'>Banking services</h3>
              <p className='disclaimer'>Banking services provided by Coastal Community Bank, Member FDIC. Till accounts are FDIC insured up to $250,000 per depositor through Coastal Community Bank, Member FDIC. The Till Visa Card is issued by Coastal Community Bank pursuant to license by Visa U.S.A. Inc. Apple&reg;, App Store&reg;, and iPhone&reg; are registered trademarks of Apple Inc.</p>
            </div>
          </div>
          <div className='row space-between padding'>
            <div className='mb'>
              <Logo/>
            </div>
            <div className='row'>
              <div><a href='https://www.instagram.com/tillfinancial/'><Instagram className='social-logo'/></a></div>
              <div className='x-logo'><a href='https://www.twitter.com/tillfinancial/'><Xtwitter /></a></div>
              <div><a href='https://www.linkedin.com/company/tillfinancial/'><LinkedIn className='social-logo'/></a></div>
              <div><a href='https://www.facebook.com/tillfinancial/'><Facebook className='social-logo'/></a></div>
            </div>
          </div>
          <div className='separator gray200' />
          <div className='padding'>
            <div>
                <p className='disclosure mb'>Important Disclosures</p>
                <p className="disclosure">Cellular network, messaging, and data rates may apply when using the Till app. Contact your carrier for details.</p>
            </div>
          </div>
      </footer>
    </div>
  );
}
