import { useRef } from 'react';
import * as Yup from 'yup';

import { Formik, Field, Form, FormikProps, ErrorMessage } from 'formik';
import { FormControl } from '@mui/material';

import { AppConfig } from '../../../../app/config';
import { useAppSelector } from '../../../../app/hooks';

import { TextField } from '../../../../components';
import './AddressForm.scss';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .min(3, 'Required'),
  lastName: Yup.string()
    .required('Required')
    .min(3, 'Required'),
  address1: Yup.string()
    .required('Required'),
  city: Yup.string()
    .required('Required'),
  state: Yup.mixed<string>()
  .oneOf(AppConfig.AddressScreen.AddressForm.Geo.US.States.map((s)=> s.label))
  .required('Required'),
  postalcode: Yup.string()
  .required('Required')
  .length(5, 'Required'),
})

export interface AddressFormType {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalcode: string;  
}

interface AddressFormProps {
  onSubmit: (form: AddressFormType) => void;
}

export function AddressForm(props: AddressFormProps) {

  const formRef = useRef<FormikProps<AddressFormType>>(null);
  const currentFormState = useAppSelector(state => state.address.form);

  const initialValues: AddressFormType = {
    firstName: currentFormState.firstName,
    lastName: currentFormState.lastName,
    address1: currentFormState.address1,
    address2: currentFormState.address2,
    city: currentFormState.city,
    state: currentFormState.state,
    postalcode: currentFormState.postalcode,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
      innerRef={formRef}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form className='address-form-component till-form'>
            <div className='address-fields-container'>
              <div className='address-row'>
                <Field
                  className='textfield'
                  component={TextField}
                  name='firstName'
                  label='First name'
                  disabled={isSubmitting}
                  placeholder={'First name (ex: John)'}
                />
                <Field
                  className='textfield'
                  component={TextField}
                  name='lastName'
                  label='Last name'
                  disabled={isSubmitting}
                  placeholder={'Last name (ex: Doe)'}
                />
              </div>
              <Field
                className='textfield'
                component={TextField}
                name='address1'
                label='Address 1'
                disabled={isSubmitting}
                placeholder={'Address 1 (ex: 31 Spooner Street)'}
              />
              <Field
                className='textfield'
                component={TextField}
                name='address2'
                label='Address 2'
                disabled={isSubmitting}
                placeholder={'Address 2 (ex: Apt 2)'}
              />
                <Field
                  className='textfield'
                  component={TextField}
                  name='city'
                  label='City'
                  disabled={isSubmitting}
                  placeholder={'City (ex: Quahog)'}
                />
                <div className='address-row'>
                  <FormControl>
                    <Field as="select" name="state" className='state-control'>
                      <option disabled value=''>State</option>
                      {AppConfig.AddressScreen.AddressForm.Geo.US.States.map((s,i)=> <option key={`${i}-state-key`} value={s.label}>{s.code}</option>)}
                    </Field>
                    <ErrorMessage component="div" name="state" className="invalid-state-feedback"/>  
                  </FormControl>
                  
                  <Field
                    className='textfield'
                    component={TextField}
                    name='postalcode'
                    label='Zip Code'
                    disabled={isSubmitting}
                    placeholder={'Zip code (ex: 02808)'}
                  />
                </div>
            </div>
            <div className='address-button-container'>
              <button type='submit' className='address-button' disabled={isSubmitting}>Next</button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
