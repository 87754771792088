
export type AppConfigType = {
    AddressScreen: {
        AddressForm: {
            Geo: {
              US: {
                States: { code: string; label: string }[];
              };
            };
        };
    }
}
    
export const AppConfig: AppConfigType = {
    AddressScreen: {
        AddressForm: {
            Geo: {
              US: {
                States: [
                  { code: "AL", label: "Geo-US-State-AL" },
                  { code: "AK", label: "Geo-US-State-AK" },
                  { code: "AZ", label: "Geo-US-State-AZ" },
                  { code: "AR", label: "Geo-US-State-AR" },
                  { code: "CA", label: "Geo-US-State-CA" },
                  { code: "CO", label: "Geo-US-State-CO" },
                  { code: "CT", label: "Geo-US-State-CT" },
                  { code: "DE", label: "Geo-US-State-DE" },
                  { code: "DC", label: "Geo-US-State-DC" },
                  { code: "FL", label: "Geo-US-State-FL" },
                  { code: "GA", label: "Geo-US-State-GA" },
                  { code: "HI", label: "Geo-US-State-HI" },
                  { code: "ID", label: "Geo-US-State-ID" },
                  { code: "IL", label: "Geo-US-State-IL" },
                  { code: "IN", label: "Geo-US-State-IN" },
                  { code: "IA", label: "Geo-US-State-IA" },
                  { code: "KS", label: "Geo-US-State-KS" },
                  { code: "KY", label: "Geo-US-State-KY" },
                  { code: "LA", label: "Geo-US-State-LA" },
                  { code: "ME", label: "Geo-US-State-ME" },
                  { code: "MD", label: "Geo-US-State-MD" },
                  { code: "MA", label: "Geo-US-State-MA" },
                  { code: "MI", label: "Geo-US-State-MI" },
                  { code: "MN", label: "Geo-US-State-MN" },
                  { code: "MS", label: "Geo-US-State-MS" },
                  { code: "MO", label: "Geo-US-State-MO" },
                  { code: "MT", label: "Geo-US-State-MT" },
                  { code: "NE", label: "Geo-US-State-NE" },
                  { code: "NV", label: "Geo-US-State-NV" },
                  { code: "NH", label: "Geo-US-State-NH" },
                  { code: "NJ", label: "Geo-US-State-NJ" },
                  { code: "NM", label: "Geo-US-State-NM" },
                  { code: "NY", label: "Geo-US-State-NY" },
                  { code: "NC", label: "Geo-US-State-NC" },
                  { code: "ND", label: "Geo-US-State-ND" },
                  { code: "OH", label: "Geo-US-State-OH" },
                  { code: "OK", label: "Geo-US-State-OK" },
                  { code: "OR", label: "Geo-US-State-OR" },
                  { code: "PA", label: "Geo-US-State-PA" },
                  { code: "PR", label: "Geo-US-State-PR" },
                  { code: "RI", label: "Geo-US-State-RI" },
                  { code: "SC", label: "Geo-US-State-SC" },
                  { code: "SD", label: "Geo-US-State-SD" },
                  { code: "TN", label: "Geo-US-State-TN" },
                  { code: "TX", label: "Geo-US-State-TX" },
                  { code: "UT", label: "Geo-US-State-UT" },
                  { code: "VT", label: "Geo-US-State-VT" },
                  { code: "VA", label: "Geo-US-State-VA" },
                  { code: "VI", label: "Geo-US-State-VI" },
                  { code: "WA", label: "Geo-US-State-WA" },
                  { code: "WV", label: "Geo-US-State-WV" },
                  { code: "WI", label: "Geo-US-State-WI" },
                  { code: "WY", label: "Geo-US-State-WY" },
                ],
              },
            },
        },
    }
}
