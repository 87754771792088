import { useNavigate} from 'react-router-dom';
import { ConfirmSendCard } from '../../components';
import { confirmSend } from '../../confirmSendSlice';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';

import './ConfirmSendRoute.scss';

export function ConfirmSendRoute() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const startState = useAppSelector(state => state.start);
  
  const handleOnSubmit = () => {
    dispatch(confirmSend({note: startState.form.note}));
    navigate("../success", { relative: "path" })
  };

  return (
    <div className='confirm-send-route-component'>
      <ConfirmSendCard 
        onSubmit={handleOnSubmit} 
        note={startState.form.note} 
        recipient={startState.givenName} 
        dollarAmount={startState.form.amount} 
        sender={startState.form.from}
      />
    </div>
  );
}
