import { useRef } from 'react';
import * as Yup from 'yup';

import { Formik, Field, Form, FormikProps } from 'formik';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useAppSelector } from '../../../../app/hooks';

import { TextField } from '../../../../components';
import './InformationForm.scss';

// eslint-disable-next-line
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email().required('Required'),
  phone: Yup.string()
    .required('Required')
    .matches(phoneRegExp, 'Phone number is not valid'),
})

export interface InformationFormType {
  email: string;
  phone: string;
  subscribed: boolean;
}

interface InformationFormProps {
  onSubmit: (form: InformationFormType) => void;
}

export function InformationForm( props:InformationFormProps ) {
  const formRef = useRef<FormikProps<InformationFormType>>(null);
  const currentFormState = useAppSelector(state => state.information.form);
  
  const initialValues: InformationFormType = {
    email: currentFormState.email,
    phone: currentFormState.phone,
    subscribed: currentFormState.subscribed,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
      innerRef={formRef}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form className='information-form-component till-form'>
            <div className='information-fields-container'>
              
              <Field
                className='textfield'
                component={TextField}
                name='email'
                label='Email address'
                disabled={isSubmitting}
                placeholder={'Email address(ex: johndoe@gmail.com)'}
              />
              <Field
                className='textfield'
                component={TextField}
                name='phone'
                label='Phone number'
                disabled={isSubmitting}
                placeholder={'Phone number (ex: (555) 555-5555)'}
              />
           
              <FormControlLabel
                control={
                  <Checkbox name="subscribed" checked={values.subscribed}
                  onChange={() => formRef.current?.setFieldValue('subscribed', !values.subscribed)}/>
                }
                label="I’d like to receive updates from Till"
                sx={{ 
                  '& .MuiSvgIcon-root': 
                    { fontSize: 28,
                      color: 'black',
                      '&.Mui-checked': {
                        color: 'black',
                      },
                    },
                }}
              />
            </div>
            <div className='information-button-container'>
              <button type='submit' className='information-button'>Next</button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
