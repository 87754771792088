import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import * as Models from '../../models';
import { PaylinkUserAPIResponse, tillDatasource } from '../../datasource';

export interface PaylinkUserState {
  setPaylinkUserAPI: APIResult<string>;
  form: {
    email:string;
    phone:string;
    subscribed:boolean;
  }
}

const initialState: PaylinkUserState = {
  setPaylinkUserAPI: emptyAPIResult(),
  form: {
    email: "",
    phone: "",
    subscribed: false,
  }
}

export const setPaylinkUser = createAsyncThunk<PaylinkUserAPIResponse, Models.PaylinkUser,{ rejectValue: ApiError }>(
  'api/account',
  async (params, { rejectWithValue }) => {
    
    try {
      const data = await tillDatasource.setPaylinkUser(params);
      return data;

    } catch (err: any) {
      
      const error = err.response ? {
        ...err.response
      } : defaultError;

      return rejectWithValue(error);
    }
  }
);

export const informationSlice = createSlice({
  name: 'information',
  initialState,
  reducers: {
    updateForm(state, action) {
      state.form = action.payload;
    }
  },
  extraReducers: builder => {
    builder
    .addCase(setPaylinkUser.pending, state => {
      state.setPaylinkUserAPI = loadingAPIResult();
    })
    .addCase(setPaylinkUser.fulfilled, (state, action) => {
      state.setPaylinkUserAPI = successAPIResult(JSON.stringify(action.payload));
    })
    .addCase(setPaylinkUser.rejected, (state, action) => {
      state.setPaylinkUserAPI = errorAPIResult(action.payload!);
    })
  }
});

export const { updateForm } = informationSlice.actions;
export default informationSlice.reducer;
