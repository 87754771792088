import { toDollarString } from '../../../../lib/stringRenderHelper';
import './ConfirmSendCard.scss';

interface ConfirmSendProps {
  onSubmit: () => void;
  note: string;
  dollarAmount: string;
  sender: string;
  recipient: string;
}

export function ConfirmSendCard(props: ConfirmSendProps) {
  const feePercentage = 3;
  const fee = (Number(props.dollarAmount) /100)*feePercentage;
  const total = fee + Number(props.dollarAmount);

  return (
    <div className='confirm-send-card-component'>

      <div className='title-container'>
        <h1 className='title'>Review</h1>
      </div>
      <div className='confirm-wrapper'>
        <div className='confirm-lineitem'>
          <p className='confirm-key'>Gift amount</p>
          <p className='confirm-value'>{toDollarString(props.dollarAmount)}</p>
        </div>
        <div className='separator' />
        <div className='confirm-lineitem'>
          <p className='confirm-key'>Message</p>
          <p className='confirm-value'>{props.note}</p>
        </div>
        <div className='separator' />
        <div className='confirm-lineitem'>
          <p className='confirm-key'>Fees</p>
          <p className='confirm-value'>{`${toDollarString(fee.toString())} (${feePercentage}%)`}</p>
        </div>
        <div className='separator' />
        <div className='confirm-lineitem'>
          <p className='confirm-key'>Total</p>
          <p className='confirm-value'>{toDollarString(total.toString())}</p>
        </div>
        <div className='confirm-lineitem' />
      </div>

      <div className='confirm-send-button-container'>
        <button className='confirm-send-button' onClick={props.onSubmit}>
          Confirm
        </button>
      </div>

    </div>
  );
}
