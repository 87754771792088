import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useSessionToken from '../../../../lib/hooks/useSessionToken';
import { VerifyCard } from '../../components';
import { VerifyFormType } from '../../components/VerifyForm/VerifyForm';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { updateForm, verifyPhone } from '../../verifySlice';

import './VerifyRoute.scss';
import { tillDatasource } from '../../../../datasource';

export function VerifyRoute() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {token} = useParams();

  useSessionToken(navigate, token);
  
  const verifyPhoneAPI = useAppSelector(state => state.verify.verifyPhoneAPI)

  const handleBackPress = () => {
    navigate("..", { relative: "path" });
  }

  const handleOnResendVerification = () => {
    //TODO
    //LoadingState, Success Toast
    tillDatasource.resendPhoneVerification();
  }

  const handleOnSubmit = (form: VerifyFormType) => {
    const vc = form.verificationcode;
    if(!vc)return;

    dispatch(updateForm(form));
    dispatch(verifyPhone({sms_verification_code:vc}));
    navigate('../../address', { relative: "path" })
  };

  useEffect(() => {
    if (!verifyPhoneAPI.loading) {
      if (!!verifyPhoneAPI.value) {
      } 
      
      if (!!verifyPhoneAPI.error) {
        alert("Something went wrong, please try again later");
      }
    }
  }, [verifyPhoneAPI.loading, verifyPhoneAPI.error, verifyPhoneAPI.value]);
  
  return (
    <div className='verify-route-component'>
      <VerifyCard onSubmit={handleOnSubmit} onBack={handleBackPress} onResendVerification={handleOnResendVerification} />
    </div>
  );
}