import { useNavigate, useParams } from 'react-router-dom';

import useSessionToken from '../../../../lib/hooks/useSessionToken';
import { SuccessCard } from '../../components';

import './SuccessRoute.scss';

export function SuccessRoute() {
  const navigate = useNavigate();
  const {token} = useParams();

  useSessionToken(navigate, token);

  return (
    <div className='success-route-component'>
      <SuccessCard />
    </div>
  );
}
