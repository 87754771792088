import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import * as Models from '../../models';
import { PaylinkUserAddressAPIResponse, tillDatasource } from '../../datasource';

export interface PaylinkUserAddressState {
  setPaylinkUserAddressAPI: APIResult<string>;
  form: {
    firstName:string;
    lastName:string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalcode: string;
  }
}

const initialState: PaylinkUserAddressState = {
  setPaylinkUserAddressAPI: emptyAPIResult<string>(),
  form: {
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalcode: "",
  }
}

export const setPaylinkUserAddress = createAsyncThunk<PaylinkUserAddressAPIResponse, Models.PaylinkUserAddress,{ rejectValue: ApiError }>(
  'api/account',
  async (params, { rejectWithValue }) => {
    
    try {
      const data = await tillDatasource.setPaylinkUserAddress(params);
      return data;

    } catch (err: any) {
      
      const error = err.response ? {
        ...err.response
      } : defaultError;

      return rejectWithValue(error);
    }
  }
);

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    updateForm(state, action) {
      state.form = action.payload;
    }
  },
  extraReducers: builder => {
    builder
    .addCase(setPaylinkUserAddress.pending, state => {
      state.setPaylinkUserAddressAPI = loadingAPIResult();
    })
    .addCase(setPaylinkUserAddress.fulfilled, (state, action) => {
      state.setPaylinkUserAddressAPI = successAPIResult(JSON.stringify(action.payload));
    })
    .addCase(setPaylinkUserAddress.rejected, (state, action) => {
      state.setPaylinkUserAddressAPI = errorAPIResult(action.payload!);
    })
  }
});

export const { updateForm } = addressSlice.actions;
export default addressSlice.reducer;
