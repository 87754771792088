import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { POSTPhoneVerification, VerifyPhoneAPIResponse, tillDatasource } from '../../datasource';

export interface VerifyPhoneState {
  verifyPhoneAPI: APIResult<string>;
  form: {
    verificationcode: string,
  }
}

const initialState = {
  verifyPhoneAPI: emptyAPIResult<string>(),
  form: {
    verificationcode: "",
  }
}

export const verifyPhone = createAsyncThunk<VerifyPhoneAPIResponse, POSTPhoneVerification,{ rejectValue: ApiError }>(
  'api/verify',
  async (params, { rejectWithValue }) => {
    
    try {
      const data = await tillDatasource.verifyPhone(params);
      return data;

    } catch (err: any) {
      
      const error = err.response ? {
        ...err.response
      } : defaultError;

      return rejectWithValue(error);
    }
  }
);

export const verifySlice = createSlice({
  name: 'verify',
  initialState,
  reducers: {
    updateForm(state, action) {
      state.form.verificationcode = action.payload.verificationcode;
    }
  },
  extraReducers: builder => {
    builder
    .addCase(verifyPhone.pending, state => {
      state.verifyPhoneAPI = loadingAPIResult();
    })
    .addCase(verifyPhone.fulfilled, (state, action) => {
      state.verifyPhoneAPI = successAPIResult(JSON.stringify(action.payload));
    })
    .addCase(verifyPhone.rejected, (state, action) => {
      state.verifyPhoneAPI = errorAPIResult(action.payload!);
    })
  }
});

export const { updateForm } = verifySlice.actions;
export default verifySlice.reducer;
