import './FailureRoute.scss';

export function FailureRoute() {

  return (
    <div className='failure-route-component'>
      <p>Generic Error Page</p>
    </div>
  );
}
