import { PaylinkUserAddress } from '../../../models';

export interface PaylinkUserAddressPayload {
  user_info: {
    given_name: string;
    family_name: string;
    address_1: string;
    address_2: string;
    city: string;
    region: string;
    postal_code: string;
  },
  verify: boolean,
}

export const toPaylinkUserAddressPayload = (params: PaylinkUserAddress ): PaylinkUserAddressPayload => {
  return {
    user_info: {
      given_name: params.firstName,
      family_name: params.lastName,
      address_1: params.address1,
      address_2: params.address2,
      city: params.city,
      region: params.state,
      postal_code: params.postalcode,
    },
    verify: false,
  };
}
