import { AddressForm, AddressFormType } from '../AddressForm/AddressForm';
import './AddressCard.scss';

interface AddressCardProps {
  onSubmit: (form: AddressFormType) => void;
}

export function AddressCard(props: AddressCardProps) {

  return (
    <div className='address-card-component'>

      <div className='title-container'>
        <h1 className='title'>Your address</h1>
        <h2 className='subtitle'>Please enter your billing address below</h2>
      </div>
      <div className='form-container'>
        <AddressForm onSubmit={props.onSubmit}/>
      </div>
    </div>
  );
}
