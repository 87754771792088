import { VerifyForm, VerifyFormType } from '../VerifyForm/VerifyForm';

import { BackButton } from '../../../../components';
import { useAppSelector } from '../../../../app/hooks';
import './VerifyCard.scss';

interface VerifyCardProps {
  onSubmit: (form: VerifyFormType) => void;
  onResendVerification: () => void;
  onBack: () => void;
}

export function VerifyCard(props: VerifyCardProps) {
  const previousState = useAppSelector(state => state.information.form);
  const lastfour = previousState.phone.substring(previousState.phone.length-4);

  return (
    <div className='verify-card-component'>
      <div className='back-container'>
        <BackButton onClick={props.onBack}/>
      </div>

      <div className='title-container'>
        <h1 className='title'>Verify phone number</h1>
        <h2 className='subtitle'>Enter the 6-digit verification code we sent to you at ***-***-{lastfour}</h2>
      </div>
      <div className='form-container'>
        <VerifyForm onSubmit={props.onSubmit} onResendVerification={props.onResendVerification}/>
      </div>
    </div>
  );
}