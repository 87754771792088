import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { AstraAuthCodeAPIResponse, POSTAstraAuthCode, tillDatasource } from '../../datasource';

export interface AstraState {
  astraAuthCodeAPI: APIResult<string>;
}
const initialState = {
  astraAuthCodeAPI: emptyAPIResult<string>(),
}

export const setAstraAuthCode = createAsyncThunk<AstraAuthCodeAPIResponse, POSTAstraAuthCode,{ rejectValue: ApiError }>(
  'api/auth_code',
  async (params, { rejectWithValue }) => {
    
    try {
      const data = await tillDatasource.setAstraAuthCode(params);
      return data;

    } catch (err: any) {
      
      const error = err.response ? {
        ...err.response
      } : defaultError;

      return rejectWithValue(error);
    }
  }
);

export const astraSlice = createSlice({
  name: 'astra',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
    .addCase(setAstraAuthCode.pending, state => {
      state.astraAuthCodeAPI = loadingAPIResult();
    })
    .addCase(setAstraAuthCode.fulfilled, (state, action) => {
      state.astraAuthCodeAPI = successAPIResult(JSON.stringify(action.payload));
    })
    .addCase(setAstraAuthCode.rejected, (state, action) => {
      state.astraAuthCodeAPI = errorAPIResult(action.payload!);
    })
  }
});

export default astraSlice.reducer;
