import { useRef } from 'react';
import { Formik, Field, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { useAppSelector } from '../../../../app/hooks';
import { TextField } from '../../../../components';

import './VerifyForm.scss';

const validationSchema = Yup.object().shape({
  verificationcode: Yup.string()
    .required('Required')
    .min(6, 'Required'),
})

export interface VerifyFormType {
  verificationcode: string;
}

interface VerifyFormProps {
  onSubmit: (form: VerifyFormType) => void;
  onResendVerification: () => void;
}

export function VerifyForm(props:VerifyFormProps) {
  const formRef = useRef<FormikProps<VerifyFormType>>(null);
  const currentFormState = useAppSelector(state => state.verify.form);
  
  const initialValues: VerifyFormType = {
    verificationcode: currentFormState.verificationcode,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
      innerRef={formRef}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form className='verify-form-component till-form'>
            <div className='verify-fields-container'>
              <Field
                className='textfield'
                component={TextField}
                name='verificationcode'
                label='Verification code'
                disabled={isSubmitting}
              />
            </div>
            <div className='resend-cta-container'>
              <button type='button' className='resend-cta' onClick={props.onResendVerification}>Resend code</button>
            </div>
            <div className='verify-button-container'>
              <button type='submit' className='verify-button'>Next</button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}