import "./status-page.scss"

interface HomePageProps {
  msg: string
}

export function StatusPageWithMsg(props:HomePageProps) {
  return (
    <div className="status-page-component">
      <pre>
        {props.msg}
      </pre>
    </div>
  )
}
