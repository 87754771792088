import { createSlice } from '@reduxjs/toolkit';

export const startSlice = createSlice({
  name: 'start',
  initialState: (() => {
    const dataString = sessionStorage.getItem('start_form')
    let initial = {from:'',amount:'',note:'',};
    
    if (!!dataString) {
      initial = JSON.parse(dataString)
    }
    
    return {
      givenName: "",
      form: {
        from: initial.from,
        amount: initial.amount,
        note: initial.note,
      }
    }
  })(),
  reducers: {
    updateForm(state, action) {
      state.form = action.payload;
    },
    updateGivenName(state, action) {
      state.givenName = action.payload;
    }
  },
});

export const { updateForm, updateGivenName } = startSlice.actions;
export default startSlice.reducer;
