import { useRef } from 'react';
import { Formik, Field, Form, FormikProps } from 'formik';
import * as Yup from 'yup';

import { useAppSelector } from '../../../../app/hooks';
import { TextField } from '../../../../components';

import './StartForm.scss';

const validationSchema = Yup.object().shape({
  from: Yup.string()
    .required('Your name is required')
    .min(3, 'Your name is required'),
  amount: Yup.number()
    .required('Amount is required.')
    .positive('Amount must be positive number.')
    .min(20, 'Amount must be >= $20.')
    .max(200, 'Amount can\'t exceed $200.'),
  note: Yup.string()
    .required('A short note is required'),
})

export interface StartFormType {
  from: string;
  amount: string;
  note: string;
}

interface StartFormProps {
  onSubmit: (form: StartFormType) => void;
}

export function StartForm(props:StartFormProps) {

  const formRef = useRef<FormikProps<StartFormType>>(null);
  const currentFormState = useAppSelector(state => state.start.form);

  const initialValues: StartFormType = {
    from: currentFormState.from,
    amount: currentFormState.amount,
    note: currentFormState.note,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
      innerRef={formRef}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form className='start-form-component till-form'>
            <div className='start-fields-container'>
              <Field
                className='textfield'
                component={TextField}
                name='amount'
                label='Dollar Amount'
                disabled={isSubmitting}
                placeholder={'$ Amount (ex: 25)'}
              />

              <Field
                className='textfield'
                component={TextField}
                multiline
                rows={4}
                name='note'
                label='Note'
                disabled={isSubmitting}
                placeholder={'Note (ex: A little something to kickstart your first savings account. Proud of you!)'}
              />

              <Field
                className='textfield'
                component={TextField}
                name='from'
                label='From'
                disabled={isSubmitting}
                placeholder={'From (ex: Grandpa James)'}
              />
            </div>
            <div className='start-button-container'>
              <button type='submit' className='start-button'>Next</button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
