import { Route, Routes } from 'react-router-dom';
import { Layout } from './features/layout';
import { StartRoute } from './features/start';
import { InformationRoute } from './features/information';
import { VerifyRoute } from './features/verify';
import { AddressRoute } from './features/address';
import { ConfirmSendRoute } from './features/confirmsend';
import { HeaderLayout } from './features/layout/components/HeaderLayout/HeaderLayout';
import { SuccessRoute } from './features/success';
import { AstraRoute } from './features/astra';
import { AstraSuccessRoute } from './features/astrasuccess';
import { NotFoundRoute } from './features/notfound';
import { FailureRoute } from './features/failure';

export function App() {

  return (
    <Routes>
      
      <Route path='' element={<Layout />}>
        <Route path='/failure' element={<FailureRoute />}/>
        <Route index path='/:token' element={<StartRoute />} />
        <Route path='/:token/information' element={<InformationRoute />} />
        <Route path='/:token/information/verify' element={<VerifyRoute />} />
        <Route path='/:token/address' element={<AddressRoute />} />
        <Route path='/:token/confirm' element={<ConfirmSendRoute />} />
        <Route path='/:token/astra' element={<AstraRoute />} />
      </Route>
      <Route element={<HeaderLayout />}>
        <Route path='/:token/success' element={<SuccessRoute />} />
        <Route path='*' element={<NotFoundRoute />}/>
      </Route>
      <Route path='/astra/success' element={<AstraSuccessRoute />} />
    </Routes>
  )
}
