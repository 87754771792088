import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import startReducer from '../features/start/startSlice';
import informationReducer from '../features/information/informationSlice';
import verifyReducer from '../features/verify/verifySlice';
import addressReducer from '../features/address/addressSlice';

export const store = configureStore({
  reducer: {
    start: startReducer,
    information: informationReducer,
    verify: verifyReducer,
    address: addressReducer,
  },
  middleware: (defaultMiddleware) => defaultMiddleware().concat(logger)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
