import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './AstraSuccessRoute.scss';

export function AstraSuccessRoute() {
  const [searchParams, ] = useSearchParams();
  const authCode = searchParams.get("auth_code");

  useEffect(()=>{
    window.parent.postMessage({type: 'success', payload: {success:true, auth_code:authCode}}, process.env.REACT_APP_SERVER_URL ?? '');
  },[authCode]);

  return (
    <div className='astra-success-route-component'/>
  );
}
