import { useEffect } from 'react';
import { tillDatasource } from '../../datasource';
import { NavigateFunction } from 'react-router-dom';

function useSessionToken(navigate:NavigateFunction, token: string | undefined, onValidToken?: () => void) {
  useEffect(() => {
    const sessionToken = tillDatasource.getCurrentSessionToken();
    const isRoot = window.location.pathname === `/${token}`;

    if (!sessionToken) {
      !isRoot && navigate(`/${token}`);
    } else {
      tillDatasource.setSessionToken(sessionToken);
      !!onValidToken && onValidToken();
    }
  }, [navigate, token, onValidToken]);
}

export default useSessionToken;
