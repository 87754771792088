import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../../app/hooks';
import { StartCard } from '../../components';
import { updateForm } from '../../startSlice';
import { tillDatasource } from '../../../../datasource';
import { updateGivenName } from '../../startSlice';
import { StatusPageWithMsg } from '../../../../debug/status-page';
import { StartFormType } from '../../components/StartForm/StartForm';

import './StartRoute.scss';

export function StartRoute() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSessionToken, setIsSessionToken] = useState(false);
  const {token} = useParams();

  const handleOnSubmit = (form: StartFormType) => {
    const stringData = JSON.stringify({from: form.from, amount:form.amount, note: form.note});
    dispatch(updateForm(form));
    sessionStorage.setItem('start_form', stringData);
    navigate('information');
  };

  useEffect(() => {
    
    const setToken = async () => {
      if(!token) return false;
      const response = await tillDatasource.getPaylink({token:token});
     
      if(!!response.session_token && !!response.given_name) {
        dispatch(updateGivenName(response.given_name));
        setIsSessionToken(true);
      }
    }
    
    setToken().catch(()=> navigate('./*', { relative: "path" }));
    
  }, [navigate, dispatch, token]);

  if (!isSessionToken) {
    return (<StatusPageWithMsg msg='Loading...'/>);
  }

  return (
    <div className='start-route-component'>
      <StartCard onSubmit={handleOnSubmit}/>
    </div>
  );
}
