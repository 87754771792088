import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSessionToken from '../../../../lib/hooks/useSessionToken';
import { InformationCard } from '../../components';
import { InformationFormType } from '../../components/InformationForm/InformationForm';
import { setPaylinkUser, updateForm } from '../../informationSlice';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';

import './InformationRoute.scss';

export function InformationRoute() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {token} = useParams();

  useSessionToken(navigate, token);

  const paylinkUserAPI = useAppSelector(state => state.information.setPaylinkUserAPI);

  const handleOnSubmit = (form: InformationFormType) => {
    dispatch(updateForm(form));
    dispatch(setPaylinkUser(form));
    navigate('verify');
  };

  useEffect(() => {

    if (!paylinkUserAPI.loading) {
      if (!!paylinkUserAPI.value) {
        // success
      } 
      
      if (!!paylinkUserAPI.error) {
        alert("Something went wrong, please try again later");
      }
    }
  }, [paylinkUserAPI.loading, paylinkUserAPI.error, paylinkUserAPI.value]);

  return (
    <div className='information-route-component'>
      <InformationCard onSubmit={handleOnSubmit}/>
    </div>
  );
}
