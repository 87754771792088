import { PaylinkUser } from '../../../models';

export interface PaylinkUserPayload {
  user_info: {
    email: string;
    phone: string;
    email_opt_in: boolean;
  },
  verify: boolean,
}

export const toPaylinkUserPayload = (params: PaylinkUser ): PaylinkUserPayload => {
  return {
    user_info: {  
      email: params.email,
      phone: params.phone,
      email_opt_in: params.subscribed,
    },
    verify: true,
  };
}
