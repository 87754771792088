import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, ApiError, defaultError } from '../../lib/redux';
import { POSTConfirmSend, ConfirmSendAPIResponse, tillDatasource } from '../../datasource';

export interface ConfirmSendState {
  confirmSendAPI: APIResult<string>;
}
const initialState = {
  confirmSendAPI: emptyAPIResult(),
}

export const confirmSend = createAsyncThunk<ConfirmSendAPIResponse, POSTConfirmSend,{ rejectValue: ApiError }>(
  'api/submit',
  async (params, { rejectWithValue }) => {
    
    try {
      const data = await tillDatasource.confirmSend(params);
      return data;

    } catch (err: any) {
      
      const error = err.response ? {
        ...err.response
      } : defaultError;

      return rejectWithValue(error);
    }
  }
);

export const confirmSendSlice = createSlice({
  name: 'confirmSend',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
    .addCase(confirmSend.pending, state => {
      state.confirmSendAPI = loadingAPIResult();
    })
    .addCase(confirmSend.fulfilled, (state, action) => {
      state.confirmSendAPI = successAPIResult(action.payload.toString());
    })
    .addCase(confirmSend.rejected, (state, action) => {
      state.confirmSendAPI = errorAPIResult(action.payload!);
    })
  }
});

export default confirmSendSlice.reducer;
