import { InformationForm, InformationFormType } from '../InformationForm/InformationForm';
import { BackButton } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import './InformationCard.scss';

interface InformationCardProps {
  onSubmit: (form: InformationFormType) => void;
}

export function InformationCard(props: InformationCardProps) {
  const navigate = useNavigate();

  const handleBackPress = () => {
    navigate("..", { relative: "path" })
  }

  return (
    <div className='information-card-component'>
      <div className='back-container'>
        <BackButton onClick={handleBackPress}/>
      </div>

      <div className='title-container'>
        <h1 className='title'>Contact information</h1>
      </div>
      <div className='form-container'>
        <InformationForm onSubmit={props.onSubmit} />
      </div>
    </div>
  );
}
