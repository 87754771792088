import { useEffect } from 'react';
import { useNavigate} from 'react-router-dom';

import { AddressCard } from '../../components';
import { setPaylinkUserAddress, updateForm } from '../../addressSlice';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { AddressFormType } from '../../components/AddressForm/AddressForm';

import './AddressRoute.scss';

export function AddressRoute() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const paylinkUserAddressAPI = useAppSelector(state => state.address.setPaylinkUserAddressAPI);
  const verifyPhoneAPI = useAppSelector(state => state.verify.verifyPhoneAPI);

  const handleOnSubmit = (form: AddressFormType) => {
    dispatch(updateForm(form));
    dispatch(setPaylinkUserAddress(form));
    navigate('../astra', { relative: "path" });
  };

  useEffect(() => {

    if (!paylinkUserAddressAPI.loading) {
      if (!!paylinkUserAddressAPI.value) {
      } 
      
      if (!!paylinkUserAddressAPI.error) {
        alert("Something went wrong, please try again later");
      }
    }
  }, [paylinkUserAddressAPI.loading, paylinkUserAddressAPI.error, paylinkUserAddressAPI.value]);

  return (
    <div className='address-route-component'>
      <AddressCard onSubmit={handleOnSubmit} />
    </div>
  );
}
