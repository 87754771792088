import './AstraCard.scss';

interface AstraCardProps {
  astraOauthUrl: string;
}

export function AstraCard(props:AstraCardProps) {

  return (
    <div className='astra-card-component'>
      <iframe title='Astra Financial' className='astra-frame' src={props.astraOauthUrl} />
    </div>
  );
}
