import Cat404Image from '../../../../assets/images/404cat.png';
import './NotFoundCard.scss';

export function NotFoundCard() {

  return (
    <div className='not-found-card-component flex-center'>
        <div className='splash-container'>
          <img src={Cat404Image} className='not-found-image' alt='404 error screen, the requested resource was not found.'/>
        </div>
        <h1>Oops! Something has gone wrong</h1>
        <div className='not-found-cta-wrapper'>
            <p><a className='not-found-cta' href="mailto:help@tillfinancial.io">Contact Support</a></p>
        </div>
    </div>
  );
}
