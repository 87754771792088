import { Outlet } from 'react-router-dom';

import TillCardImage from '../../../../assets/images/till_card.png';
import BlueBGImage from '../../../../assets/images/blue_bg.jpg';
import './Layout.scss';

export function Layout() {

  return (
    <div className='layout-component'>
      <div className='sidebar-container'>
        <img src={BlueBGImage} className='sidebar-bg-img' alt='' />
        <img src={TillCardImage} className='sidebar-card-img' alt='' />
      </div>
      <div className='main-container'>
        <div className='content-container'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
