import { Outlet } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../assets/images/logo.svg';

import './HeaderLayout.scss';

export function HeaderLayout() {

  return (
    <div className='header-layout-component'>
      <header className='flex-center'>
        <div className='logo-container'>
          <Logo />
        </div>
      </header>
      <div className='main-container'>
        <div className='content-container'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
