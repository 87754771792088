import { NotFoundCard } from '../../components';
import './NotFoundRoute.scss';

export function NotFoundRoute() {

  return (
    <div className='not-found-route-component'>
      <NotFoundCard />
    </div>
  );
}
