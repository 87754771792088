import { ReactComponent as Logo } from '../../../../assets/images/logo.svg';
import { useAppSelector } from '../../../../app/hooks';
import { StartForm, StartFormType } from '../StartForm/StartForm';

import './StartCard.scss';

interface StartCardProps {
  onSubmit: (form: StartFormType) => void;
}

export function StartCard(props:StartCardProps) {

  const currentState = useAppSelector(state => state.start);

  return (
    <div className='start-card-component'>
      <div className='logo-container'>
        <Logo />
      </div>
      <div className='title-container'>
        <h1 className='title'>Send money to <span>{currentState.givenName}</span></h1>
        <h2 className='subtitle'>Funds will be sent directly to their Till card</h2>
      </div>
      <div className='form-container'>
        <StartForm onSubmit={props.onSubmit}/>
      </div>
      <div className='disclaimer-container'>
        <p>By clicking &ldquo;Next&rdquo; you agree to Till&rsquo;s pay link Terms of Use and <a href='https://tillfinancial.io/privacy/' target='_blank' rel="noreferrer">Privacy Statement</a></p>
      </div>
    </div>
  );
}
