import axios, { AxiosInstance } from 'axios';

import * as Interface from './TillDatasourceInterface';
import * as Models from '../../models';
import * as APIModels from './models';
import * as denormalizers from './denormalizers';

export interface TillDatasourceConfig {
  baseURL: string;
}

export class TillDatasource implements Interface.TillDatasourceInterface {
  private client: AxiosInstance;

  constructor(config: TillDatasourceConfig) {
    const { baseURL } = config;

    this.client = axios.create({
      baseURL,
    });

    this.client.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response) {
        // for debug, need to handle this more gracefully
        alert(JSON.stringify(error.response));
      }
      return Promise.reject(error);
    });
  }

  // Set Session Token
  setSessionToken(st: string) {
    this.client.defaults.headers.common['Authorization'] = `Bearer ${st}`;
    sessionStorage.setItem('session_token', st);
  }

  // Get Session Token from session storage
  getCurrentSessionToken(): string | null {
    return sessionStorage.getItem('session_token');
  }

  // Get Till Beneficary Name and Initial Session Token 
  async getPaylink(params: Interface.PaylinkParams): Promise<APIModels.PaylinkAPIResponse> {
    const { data } = await this.client.get(`/api/paylink/${params.token}`);

    this.setSessionToken(data.session_token);

    return {
      given_name: data.given_name,
      session_token: data.session_token,
    };
  }

  // Submit Paylink User Details
  async setPaylinkUser(params: Models.PaylinkUser): Promise<APIModels.PaylinkUserAPIResponse> {
    const payload = denormalizers.toPaylinkUserPayload(params);
    const { data } = await this.client.post('/api/account', payload);
    
    this.setSessionToken(data.session_token);

    return data.session_token;
  }

  // Submit Paylink User Address Details
  async setPaylinkUserAddress(params: Models.PaylinkUserAddress): Promise<APIModels.PaylinkUserAddressAPIResponse> {
    const payload = denormalizers.toPaylinkUserAddressPayload(params);
    const { data } = await this.client.post('/api/account', payload);
    
    this.setSessionToken(data.session_token);

    return data.session_token;
  }

  // Resend Phone Verification Request
  async resendPhoneVerification(): Promise<APIModels.ResendPhoneVerificationAPIResponse> {
    const { data } = await this.client.post('/api/account', {verify:true});
    
    this.setSessionToken(data.session_token);

    return data.session_token;
  }

  // Submit Phone Verification Code
  async verifyPhone(params: Interface.POSTPhoneVerification): Promise<APIModels.VerifyPhoneAPIResponse> {
    const { data } = await this.client.post('/api/verify', params);
    
    this.setSessionToken(data.session_token);

    return data.session_token;
  }

  // Submit Astra Auth Code
  async setAstraAuthCode(params: Interface.POSTAstraAuthCode): Promise<APIModels.AstraAuthCodeAPIResponse> {
    const { data } = await this.client.post('/api/auth_code', params);
    
    this.setSessionToken(data.session_token);

    return data.session_token;
  }

  // Submit Form / Pay Recipient
  async confirmSend(params: Interface.POSTConfirmSend): Promise<APIModels.ConfirmSendAPIResponse> {
    const { data } = await this.client.post('/api/submit', params);
    
    this.setSessionToken(data.session_token);

    return data.session_token;
  }
}
